.slidecontainer {
    width: 100%;
  }
  
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background: #009FDA;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2);
  }

  input[type=range].ie-slider {
    width: 100%;
    background-color: transparent;
  }

  input[type=range].ie-slider::-ms-thumb {
    border: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #009FDA;
    cursor: pointer;
  }

  input[type=range].ie-slider::-ms-track {
    width: 100%;
    height: 2px;
    background: transparent;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent;
  }

  input[type=range].ie-slider::-ms-fill-lower {
    background: #009FDA;
    border-radius: 10px;
  }

  input[type=range].ie-slider::-ms-fill-upper {
    background:#009FDA;
    border-radius:10px;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #009FDA;
    cursor: pointer;
  }

  .slider::-webkit-slider-thumb:active {
    width: 22px;
    height: 22px;
  }
  
  .slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #009FDA;
    cursor: pointer;
  }