@font-face {
  font-family: 'AgentPlatformFonts';
  src:
    url('fonts/AgentPlatformFonts.ttf?z7o8m1') format('truetype'),
    url('fonts/AgentPlatformFonts.woff?z7o8m1') format('woff'),
    url('fonts/AgentPlatformFonts.svg?z7o8m1#AgentPlatformFonts') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'AgentPlatformFonts' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dnc-hm:before {
  content: "\e944";
}
.icon-dnc-ntl:before {
  content: "\e945";
}
.icon-dnc-withx-bttn:before {
  content: "\e93f";
}
.icon-email-contact-button:before {
  content: "\e942";
}
.icon-email-dnc-button:before {
  content: "\e941";
}
.icon-text-contact-button:before {
  content: "\e940";
}
.icon-text-dnc-button:before {
  content: "\e927";
}
.icon-call-check-bttn:before {
  content: "\e93d";
}
.icon-dnc-bttn:before {
  content: "\e93e";
}
.icon-location-bttn:before {
  content: "\e937";
}
.icon-call-bttn:before {
  content: "\e938";
}
.icon-completed:before {
  content: "\e939";
}
.icon-email-bttn:before {
  content: "\e93a";
}
.icon-note-bttn:before {
  content: "\e93b";
}
.icon-text-bttn:before {
  content: "\e93c";
}
.icon-received:before {
  content: "\e914";
}
.icon-sent:before {
  content: "\e934";
}
.icon-leaderboard:before {
  content: "\e935";
}
.icon-punch:before {
  content: "\e936";
}
.icon-check:before {
  content: "\e900";
}
.icon-trophy:before {
  content: "\e901";
}
.icon-home:before {
  content: "\e902";
}
.icon-power:before {
  content: "\e903";
}
.icon-lock:before {
  content: "\e904";
}
.icon-key:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e906";
}
.icon-searchPlus:before {
  content: "\e907";
}
.icon-searchMinus:before {
  content: "\e908";
}
.icon-add:before {
  content: "\e909";
}
.icon-cancel:before {
  content: "\e90a";
}
.icon-edit:before {
  content: "\e90b";
}
.icon-clock:before {
  content: "\e90c";
}
.icon-location:before {
  content: "\e90d";
}
.icon-compass:before {
  content: "\e90e";
}
.icon-navigation:before {
  content: "\e90f";
}
.icon-star:before {
  content: "\e910";
}
.icon-flag:before {
  content: "\e911";
}
.icon-tools:before {
  content: "\e912";
}
.icon-launch:before {
  content: "\e913";
}
.icon-train:before {
  content: "\e915";
}
.icon-money:before {
  content: "\e916";
}
.icon-badge:before {
  content: "\e917";
}
.icon-send:before {
  content: "\e918";
}
.icon-download:before {
  content: "\e919";
}
.icon-documents:before {
  content: "\e91a";
}
.icon-arrowRight:before {
  content: "\e91b";
}
.icon-arrowLeft:before {
  content: "\e91c";
}
.icon-arrowUp:before {
  content: "\e91d";
}
.icon-arrowDown:before {
  content: "\e91e";
}
.icon-folder:before {
  content: "\e91f";
}
.icon-settings:before {
  content: "\e920";
}
.icon-trash:before {
  content: "\e921";
}
.icon-calendar:before {
  content: "\e922";
}
.icon-attachment:before {
  content: "\e923";
}
.icon-image:before {
  content: "\e924";
}
.icon-quoteSquare:before {
  content: "\e925";
}
.icon-linkChain:before {
  content: "\e926";
}
.icon-mail:before {
  content: "\e943";
}
.icon-quoteCircles:before {
  content: "\e928";
}
.icon-linkConnect:before {
  content: "\e929";
}
.icon-phone:before {
  content: "\e92a";
}
.icon-phoneSound:before {
  content: "\e92b";
}
.icon-arrowForward:before {
  content: "\e92c";
}
.icon-arrowBack:before {
  content: "\e92d";
}
.icon-soundMute:before {
  content: "\e92e";
}
.icon-soundOn:before {
  content: "\e92f";
}
.icon-lead:before {
  content: "\e930";
}
.icon-leadAdd:before {
  content: "\e931";
}
.icon-leadMinus:before {
  content: "\e932";
}
.icon-leadMultiple:before {
  content: "\e933";
}
