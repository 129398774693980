html,
body,
#root {
    touch-action: manipulation;
    /* -ms-overflow-style: -ms-autohiding-scrollbar; */
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
    height: 100% !important;
    width: 100% !important;
    background-color: #f1f1f1;
    position: 'absolute';
}

.desktop,
.desktop #root {
    overflow: auto !important;
}

.desktop ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.desktop ::-webkit-scrollbar-track {
  background: #F6F4F2; 
}
.desktop ::-webkit-scrollbar-thumb {
  background: #DDDDDD;
  border-radius: 10px;
}
.desktop ::-webkit-scrollbar-thumb:hover {
  background: #4D4D4D; 
}





/** FAB classes */
.fab-child {
    -webkit-transition: all 0.32s ease !important;
    -moz-transition: all 0.32s ease !important;
    transition: all 0.32s ease !important;
    opacity: 1;
}
.fab-child-closed{
    opacity: 0 !important;
    bottom: 40px !important;
    z-index: -1;
}
.fab-child-open{
    opacity: 1 !important;
    z-index: 4;
    padding: 8px 0px 8px 0px;
}
.fab-child-label{
    opacity: 0 !important;
}

/* Not sure if below are needed */
/* .sticky{
    position: -webkit-sticky;
    position: sticky;
}

.white-input::before, .white-input::after{
    background-color: white !important;
}
.white-input{
    color: white !important;
    background: transparent;
}
.primary-text{
    color: #4D4D4D;
    font-size: 15px;
    padding-bottom: 3px;
    white-space: pre-wrap;
    word-wrap: break-word;
}
.secondary-text{
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 13px;
    color: #8D8D8D;
}
.vertical-divider-right {
    border-right: 1px solid #E0E0E0;
} */

/** Image Uploader classes */
.cropper-container {
    direction: ltr;
    font-size: 0;
    line-height: 0;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .cropper-container img {/*Avoid margin top issue (Occur only when margin-top <= -height)
   */
    display: block;
    height: 100%;
    image-orientation: 0deg;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 100%;
  }
  
  .cropper-wrap-box,
  .cropper-canvas,
  .cropper-drag-box,
  .cropper-crop-box,
  .cropper-modal {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .cropper-wrap-box,
  .cropper-canvas {
    overflow: hidden;
  }
  
  .cropper-drag-box {
    background-color: #fff;
    opacity: 0;
  }
  
  .cropper-modal {
    background-color: #000;
    opacity: .5;
  }
  
  .cropper-view-box {
    display: block;
    height: 100%;
    outline-color: rgba(51, 153, 255, 0.75);
    outline: 1px solid #39f;
    overflow: hidden;
    width: 100%;
  }
  
  .cropper-dashed {
    border: 0 dashed #eee;
    display: block;
    opacity: .5;
    position: absolute;
  }
  
  .cropper-dashed.dashed-h {
    border-bottom-width: 1px;
    border-top-width: 1px;
    height: 33.33333%;
    left: 0;
    top: 33.33333%;
    width: 100%;
  }
  
  .cropper-dashed.dashed-v {
    border-left-width: 1px;
    border-right-width: 1px;
    height: 100%;
    left: 33.33333%;
    top: 0;
    width: 33.33333%;
  }
  
  .cropper-center {
    display: block;
    height: 0;
    left: 50%;
    opacity: .75;
    position: absolute;
    top: 50%;
    width: 0;
  }
  
  .cropper-center:before,
  .cropper-center:after {
    background-color: #eee;
    content: ' ';
    display: block;
    position: absolute;
  }
  
  .cropper-center:before {
    height: 1px;
    left: -3px;
    top: 0;
    width: 7px;
  }
  
  .cropper-center:after {
    height: 7px;
    left: 0;
    top: -3px;
    width: 1px;
  }
  
  .cropper-face,
  .cropper-line,
  .cropper-point {
    display: block;
    height: 100%;
    opacity: .1;
    position: absolute;
    width: 100%;
  }
  
  .cropper-face {
    background-color: #fff;
    left: 0;
    top: 0;
  }
  
  .cropper-line {
    background-color: #39f;
  }
  
  .cropper-line.line-e {
    cursor: ew-resize;
    right: -3px;
    top: 0;
    width: 5px;
  }
  
  .cropper-line.line-n {
    cursor: ns-resize;
    height: 5px;
    left: 0;
    top: -3px;
  }
  
  .cropper-line.line-w {
    cursor: ew-resize;
    left: -3px;
    top: 0;
    width: 5px;
  }
  
  .cropper-line.line-s {
    bottom: -3px;
    cursor: ns-resize;
    height: 5px;
    left: 0;
  }
  
  .cropper-point {
    background-color: #39f;
    height: 5px;
    opacity: .75;
    width: 5px;
  }
  
  .cropper-point.point-e {
    cursor: ew-resize;
    margin-top: -3px;
    right: -3px;
    top: 50%;
  }
  
  .cropper-point.point-n {
    cursor: ns-resize;
    left: 50%;
    margin-left: -3px;
    top: -3px;
  }
  
  .cropper-point.point-w {
    cursor: ew-resize;
    left: -3px;
    margin-top: -3px;
    top: 50%;
  }
  
  .cropper-point.point-s {
    bottom: -3px;
    cursor: s-resize;
    left: 50%;
    margin-left: -3px;
  }
  
  .cropper-point.point-ne {
    cursor: nesw-resize;
    right: -3px;
    top: -3px;
  }
  
  .cropper-point.point-nw {
    cursor: nwse-resize;
    left: -3px;
    top: -3px;
  }
  
  .cropper-point.point-sw {
    bottom: -3px;
    cursor: nesw-resize;
    left: -3px;
  }
  
  .cropper-point.point-se {
    bottom: -3px;
    cursor: nwse-resize;
    height: 20px;
    opacity: 1;
    right: -3px;
    width: 20px;
  }

  @media (min-width: 768px) {
    .cropper-point.point-se {
      height: 15px;
      width: 15px;
    }
  }
  
  @media (min-width: 992px) {
    .cropper-point.point-se {
      height: 10px;
      width: 10px;
    }
  }
  
  @media (min-width: 1200px) {
    .cropper-point.point-se {
      height: 5px;
      opacity: .75;
      width: 5px;
    }
  }
  
  .cropper-point.point-se:before {
    background-color: #39f;
    bottom: -50%;
    content: ' ';
    display: block;
    height: 200%;
    opacity: 0;
    position: absolute;
    right: -50%;
    width: 200%;
  }
  
  .cropper-invisible {
    opacity: 0;
  }
  
  .cropper-bg {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
  }
  
  .cropper-hide {
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }
  
  .cropper-hidden {
    display: none !important;
  }
  
  .cropper-move {
    cursor: move;
  }
  
  .cropper-crop {
    cursor: crosshair;
  }
  
  .cropper-disabled .cropper-drag-box,
  .cropper-disabled .cropper-face,
  .cropper-disabled .cropper-line,
  .cropper-disabled .cropper-point {
    cursor: not-allowed;
  }
.vertical-divider-left {
    border-left: 1px solid #E0E0E0;
}
input::-ms-clear {
    display: none;
}
.remove-badge-padding {
  padding: 0 !important;
}

.shortenWidth {
  width: 80%
}

.mce-notification {display: none!important;}

@media print{
  html,
  body,
  #root,
  #root > div{
    position: relative !important;
  }

  body,
  #root,
  #middle,
  #middle > div > div{
    overflow: visible !important;
    padding: 0 0 env(safe-area-inset-bottom) 0 !important;
  }

  #basePage.sideMenu {
    display: none !important;
  }

  div.App > div > div {
    margin-left: 0px !important;
  }

  #basePage {
    overflow: visible !important;
  }
}



.pvtUi {
  color: #2a3f5f;
  font-family: Verdana;
  border-collapse: collapse;
}
.pvtUi select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.pvtUi td.pvtOutput {
  vertical-align: top;
}

table.pvtTable {
  font-size: 8pt;
  text-align: left;
  border-collapse: collapse;
  margin-top: 3px;
  margin-left: 3px;
  font-family: Verdana;
}
table.pvtTable thead tr th,
table.pvtTable tbody tr th {
  background-color: #ebf0f8;
  border: 1px solid #c8d4e3;
  font-size: 8pt;
  padding: 5px;
}

table.pvtTable .pvtColLabel {
  text-align: center;
}
table.pvtTable .pvtTotalLabel {
  text-align: right;
}

table.pvtTable tbody tr td {
  color: #2a3f5f;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #c8d4e3;
  vertical-align: top;
  text-align: right;
}

.pvtTotal,
.pvtGrandTotal {
  font-weight: bold;
}

.pvtRowOrder,
.pvtColOrder {
  cursor: pointer;
  width: 15px;
  margin-left: 5px;
  display: inline-block;
  user-select: none;
  text-decoration: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.pvtAxisContainer,
.pvtVals {
  border: 1px solid #a2b1c6;
  background: #f2f5fa;
  padding: 5px;
  min-width: 20px;
  min-height: 20px;
}

.pvtRenderers {
  padding-left: 5px;
  user-select: none;
}

.pvtDropdown {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  margin: 3px;
}

.pvtDropdownIcon {
  float: right;
  color: #a2b1c6;
}
.pvtDropdownCurrent {
  text-align: left;
  border: 1px solid #a2b1c6;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  width: 210px;
  box-sizing: border-box;
  background: white;
}

.pvtDropdownCurrentOpen {
  border-radius: 4px 4px 0 0;
}

.pvtDropdownMenu {
  background: white;
  position: absolute;
  width: 100%;
  margin-top: -1px;
  border-radius: 0 0 4px 4px;
  border: 1px solid #a2b1c6;
  border-top: 1px solid #dfe8f3;
  box-sizing: border-box;
}

.pvtDropdownValue {
  padding: 2px 5px;
  font-size: 12px;
  text-align: left;
}
.pvtDropdownActiveValue {
  background: #ebf0f8;
}

.pvtVals {
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  padding-bottom: 12px;
}

.pvtRows {
  height: 35px;
}

.pvtAxisContainer li {
  padding: 8px 6px;
  list-style-type: none;
  cursor: move;
}
.pvtAxisContainer li.pvtPlaceholder {
  -webkit-border-radius: 5px;
  padding: 3px 15px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 1px dashed #a2b1c6;
}
.pvtAxisContainer li.pvtPlaceholder span.pvtAttr {
  display: none;
}

.pvtAxisContainer li span.pvtAttr {
  -webkit-text-size-adjust: 100%;
  background: #f3f6fa;
  border: 1px solid #c8d4e3;
  padding: 2px 5px;
  white-space: nowrap;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.pvtTriangle {
  cursor: pointer;
  color: #506784;
}

.pvtHorizList li {
  display: inline-block;
}
.pvtVertList {
  vertical-align: top;
}

.pvtFilteredAttribute {
  font-style: italic;
}

.sortable-chosen .pvtFilterBox {
  display: none !important;
}

.pvtCloseX {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none !important;
}

.pvtDragHandle {
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 18px;
  cursor: move;
  color: #a2b1c6;
}

.pvtButton {
  color: #506784;
  border-radius: 5px;
  padding: 3px 6px;
  background: #f2f5fa;
  border: 1px solid;
  border-color: #c8d4e3;
  font-size: 14px;
  margin: 3px;
  transition: 0.34s all cubic-bezier(0.19, 1, 0.22, 1);
  text-decoration: none !important;
}

.pvtButton:hover {
  background: #e2e8f0;
  border-color: #a2b1c6;
}

.pvtButton:active {
  background: #d1dae6;
}

.pvtFilterBox input {
  border: 1px solid #c8d4e3;
  border-radius: 5px;
  color: #506784;
  padding: 0 3px;
  font-size: 14px;
}

.pvtFilterBox input:focus {
  border-color: #119dff;
  outline: none;
}

.pvtFilterBox {
  z-index: 100;
  width: 300px;
  border: 1px solid #506784;
  background-color: #fff;
  position: absolute;
  text-align: center;
  user-select: none;
  min-height: 100px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.pvtFilterBox h4 {
  margin: 15px;
}
.pvtFilterBox p {
  margin: 10px auto;
}
.pvtFilterBox button {
  color: #2a3f5f;
}
.pvtFilterBox input[type='text'] {
  width: 230px;
  color: #2a3f5f;
  margin-bottom: 5px;
}

.pvtCheckContainer {
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow-y: scroll;
  width: 100%;
  max-height: 30vh;
  border-top: 1px solid #dfe8f3;
}

.pvtCheckContainer p {
  margin: 0;
  margin-bottom: 1px;
  padding: 3px;
  cursor: default;
}

.pvtCheckContainer p.selected {
  background: #ebf0f8;
}

.pvtOnly {
  display: none;
  width: 35px;
  float: left;
  font-size: 12px;
  padding-left: 5px;
  cursor: pointer;
}

.pvtOnlySpacer {
  display: block;
  width: 35px;
  float: left;
}

.pvtCheckContainer p:hover .pvtOnly {
  display: block;
}
.pvtCheckContainer p:hover .pvtOnlySpacer {
  display: none;
}

.pvtRendererArea {
  padding: 5px;
}
